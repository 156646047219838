import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Usercentrics } from '../usercentrics';

//utils
import { usercentricsSettingsId } from 'utils/selectors/globalsSelectors';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useIsoLang } from 'scenes/MetaData';
import { IServiceBaseInfo, IConsent } from '../usercentrics.types';

const INITIAL_CONSENT: IConsent =
  process.env.JEST_WORKER_ID !== undefined
    ? {
        Required: true,
        Analysis: true,
        Comfort: true,
        Marketing: true,
        Youtube: true,
        GoogleMaps: true,
      }
    : {
        Required: true,
        Analysis: undefined,
        Comfort: undefined,
        Marketing: undefined,
        Youtube: undefined,
        GoogleMaps: undefined,
      };

const ConsentContext = createContext<{
  consent: IConsent;
  uc: Usercentrics | undefined;
}>({ consent: INITIAL_CONSENT, uc: undefined });

export function CookieConsentProvider({ children }: Readonly<React.PropsWithChildren>) {
  const [consents, setConsents] = useState(INITIAL_CONSENT);
  const [uc, setUc] = useState<Usercentrics | undefined>();
  const uc_settingsId = useSelector(usercentricsSettingsId);
  const lang = useIsoLang();
  const isPreview = useIsPreview();
  const value = useMemo(() => ({ consent: uc ? consents : INITIAL_CONSENT, uc }), [consents, uc]);

  // only used for old usercentrics implementation - maybe not needed anymore in the future
  const fetchFromStorage = () => {
    const isNewSolution =
      window['_globalUC']?.getCategories && window['_globalUC']?.getCategories()?.length > 3;
    const storedConsent = window.localStorage.getItem('saved_uc_consent');
    if (storedConsent) {
      const parsedConsent = JSON.parse(storedConsent) || {};
      const finalConsent = Object.keys(parsedConsent).reduce((acc, cur) => {
        acc[cur] = parsedConsent[cur];
        return acc;
      }, {}) as IConsent;
      if (typeof finalConsent.Comfort === 'undefined') {
        finalConsent.Comfort = finalConsent.Analysis;
      }
      finalConsent.Youtube = isNewSolution ? finalConsent.Marketing : finalConsent.Analysis;
      finalConsent.GoogleMaps = finalConsent.Comfort;
      if (
        window['_globalUC'] &&
        !window['dataLayer'].some(({ event }) => event === 'uc_update') &&
        finalConsent.Required !== undefined &&
        finalConsent.Comfort !== undefined &&
        finalConsent.Analysis !== undefined &&
        finalConsent.Marketing !== undefined
      ) {
        const currentConsent = {
          event: 'uc_update',
          mode: 'explicit',
          Required: finalConsent.Required,
          Comfort: finalConsent.Comfort,
          Analysis: finalConsent.Analysis,
          Marketing: finalConsent.Marketing,
        };
        window['dataLayer'].push(currentConsent);
      }
      setConsents(finalConsent);
    }
  };

  const fetchConsentsNew = (event?: any) => {
    // if we have no event, we have to fetch the consents from the usercentrics object
    // this should be an exception - implemented as workaround togehter with file use-usercentrics-workaround.ts
    if (!event && typeof window.UC_UI !== 'undefined') {
      const servicesBaseInfo: IServiceBaseInfo[] = window.UC_UI.getServicesBaseInfo();
      const generalMarketingConsentGiven = servicesBaseInfo
        .filter((service) => service.categorySlug === 'marketing')
        .every((service) => service.consent.status === true);

      const generalAnalysisConsentGiven = servicesBaseInfo
        .filter((service) => service.categorySlug.startsWith('customCategory-'))
        .every((service) => service.consent.status === true);

      const generalComfortConsentGiven = servicesBaseInfo
        .filter((service) => service.categorySlug === 'functional')
        .every((service) => service.consent.status === true);

      const youtubeConsentGiven = servicesBaseInfo.filter(
        (service) => service.name === 'YouTube Video',
      )[0].consent.status;

      const googleMapsConsentGiven = servicesBaseInfo.filter(
        (service) => service.name === 'Google Maps',
      )[0].consent.status;

      const movingImageConsentGiven = servicesBaseInfo.filter(
        (service) => service.name === 'MovingImage',
      )[0].consent.status;

      const storedConsent = {
        Required: true,
        Marketing: generalMarketingConsentGiven,
        Analysis: generalAnalysisConsentGiven,
        Comfort: generalComfortConsentGiven,
        Youtube: youtubeConsentGiven,
        GoogleMaps: googleMapsConsentGiven,
        MovingImage: movingImageConsentGiven,
      };
      setConsents(storedConsent);
      return;
    }

    const currentConsent = event?.detail;
    const analysisConsentName = Object.keys(currentConsent?.ucCategory).filter((item) =>
      item.startsWith('customCategory-'),
    )[0];
    const storedConsent = {
      Required: true,
      Marketing: currentConsent?.ucCategory?.marketing || false,
      Analysis: currentConsent?.ucCategory?.[analysisConsentName] || false,
      Comfort: currentConsent?.ucCategory?.functional || false,
      Youtube: currentConsent?.['YouTube Video'] || false,
      GoogleMaps: currentConsent?.['Google Maps'] || false,
      MovingImage: currentConsent?.['MovingImage'] || false,
    };

    setConsents(storedConsent);
  };

  useEffect(() => {
    if (uc) {
      if (uc_settingsId) {
        fetchConsentsNew();
      } else {
        fetchFromStorage();
      }
    }

    const UserCentricsInitializedHandler = (_: Event) => {
      fetchConsentsNew();
    };

    if (uc_settingsId) {
      // new usercentrics implementation
      window.addEventListener('ucEvent', fetchConsentsNew);
      window.addEventListener('ucConsentEvent', fetchConsentsNew);
      window.addEventListener('UC_UI_INITIALIZED', UserCentricsInitializedHandler);
    } else {
      // old usercentrics implementation
      window.addEventListener('usercentricsUpdate', fetchFromStorage);
    }

    return () => {
      if (uc_settingsId) {
        // new usercentrics implementation
        window.removeEventListener('ucEvent', fetchConsentsNew);
        window.removeEventListener('ucConsentEvent', fetchConsentsNew);
        window.removeEventListener('UC_UI_INITIALIZED', UserCentricsInitializedHandler);
      } else {
        // old usercentrics implementation
        window.removeEventListener('usercentricsUpdate', fetchFromStorage);
      }
    };
  }, [uc_settingsId, uc]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    function initUc() {
      (async () => {
        let counter = 0;
        while (!window['_globalUC'] && !window['UC_UI'] && counter < 50) {
          await new Promise<void>((r) => {
            setTimeout(r, 200);
          });
          counter++;
        }

        const instance = new Usercentrics(window);
        instance.updateLanguage(lang.split('_')[0]);
        if (isPreview) {
          console.warn('usecentrics may be initialized with wrong langauge in preview mode');
        }
        setUc((existing) => existing || instance);
      })();
    }
    initUc();

    window.addEventListener('usercentricsLanguageChanged', initUc);

    return () => {
      window.removeEventListener('usercentricsLanguageChanged', initUc);
    };
  }, [lang, isPreview]);

  return <ConsentContext.Provider value={value}>{children}</ConsentContext.Provider>;
}

export function useUCConsent() {
  return useContext(ConsentContext).consent;
}

export function useUsercentrics() {
  return useContext(ConsentContext).uc;
}
