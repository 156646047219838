import React, { PropsWithChildren } from 'react';
import { useIsPreview } from 'utils/hooks/useIsPreview';

interface InlineEditProps {
  previewId?: string;
  isSectionReference?: boolean;
}

export function InlineEdit({
  previewId,
  children,
  isSectionReference = false,
}: PropsWithChildren<InlineEditProps>): JSX.Element {
  const isPreview = useIsPreview();

  if (isPreview && previewId && !isSectionReference) {
    return <span data-preview-id={previewId}>{children}</span>;
  }

  return <>{children}</>;
}
