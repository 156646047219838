export type ICookieType = 'comfort' | 'analysis' | 'marketing';

interface IUsercentricsDelegate {
  getCategories(): { slug: string; label: string }[];
  getActiveLanguage(): string;
  showModal();
  enableSetting(type: ICookieType);
  updateLanguage(lang: string);
}

export class Usercentrics {
  // private static _listening = false;
  private delegate: IUsercentricsDelegate;

  constructor(window) {
    if (window['UC_UI']) {
      this.delegate = new NordicsUsercentrics(window['UC_UI']);
    }
    // else if (window['_globalUC']) {
    //   this.delegate = new GenericUsercentrics(window['_globalUC']);
    //   const observer = new MutationObserver(() => {
    //     const consentModal = document.querySelector('#usercentricsConsent');
    //     if (consentModal) {
    //       ['#ucSavePrefs', '#ucAcceptAll'].forEach((id) => {
    //         const btn = consentModal.querySelector(id);
    //         if (btn) {
    //           btn.addEventListener('click', () => {
    //             window.dispatchEvent(new CustomEvent('usercentricsUpdate'));
    //           });
    //         }
    //       });
    //       observer.disconnect();
    //     }
    //   });
    //   if (!Usercentrics._listening) {
    //     Usercentrics._listening = true;
    //     observer.observe(document.body, { childList: true });
    //   }
    // }
    else {
      console.warn('no usercentrics instance prodvided! Not in ssr mode?');
    }
  }

  public getCategories(): { slug: string; label: string }[] {
    return this.delegate?.getCategories() || [];
  }

  public getActiveLanguage(): string {
    return this.delegate?.getActiveLanguage() || '';
  }

  public showModal() {
    this.delegate?.showModal();
  }

  public enableSetting(type: ICookieType) {
    this.delegate?.enableSetting(type);
  }

  public updateLanguage(lang: string) {
    this.delegate?.updateLanguage(lang);
  }

  public getLabel(type: ICookieType): string | undefined {
    return this.getCategories().find((c) => {
      if (type === 'comfort') {
        return c.slug === 'functional';
      }
      if (type === 'analysis') {
        return c.slug.startsWith('customCategory-');
      }
      return type === c.slug;
    })?.label;
  }
}

class NordicsUsercentrics implements IUsercentricsDelegate {
  private instance: any;
  constructor(instance) {
    this.instance = instance;
  }

  getCategories(): { slug: string; label: string }[] {
    return this.instance.getSettingsLabels().categories;
  }

  getActiveLanguage(): string {
    return this.instance.getActiveLanguage();
  }

  showModal() {
    this.instance.showFirstLayer();
  }

  enableSetting(type: 'comfort' | 'analysis' | 'marketing') {
    let services: { id: string }[] = [];
    switch (type) {
      case 'comfort':
        services = this.instance
          .getServicesBaseInfo()
          .filter((e) => e.categorySlug === 'functional');
        break;
      case 'analysis':
        services = this.instance
          .getServicesBaseInfo()
          .filter((e) => e.categorySlug.startsWith('customCategory-'));
        break;
      case 'marketing':
        services = this.instance
          .getServicesBaseInfo()
          .filter((e) => e.categorySlug === 'marketing');
        break;
    }

    if (services.length > 0) {
      this.instance.acceptServices(services.map((i) => i.id));
    }
  }

  updateLanguage(lang: string) {
    this.instance.updateLanguage(lang);
  }
}

// class GenericUsercentrics implements IUsercentricsDelegate {
//   private instance: any;

//   constructor(instance) {
//     this.instance = instance;
//     // the usercentrics implementation has no method to open the modal directly
//     // if we're on a page without a footer, we add a fake button which can be used
//     // to open the modal
//     if (!document.querySelector('.footer-cookie-link')) {
//       const fakeBtn = document.createElement('a');
//       fakeBtn.style.display = 'none';
//       fakeBtn.className = 'footer-cookie-link';
//       document.body.append(fakeBtn);
//     }
//   }

//   getCategories(): { slug: string; label: string }[] {
//     return this.instance.getCategories();
//   }

//   getActiveLanguage(): string {
//     return this.instance.apiInstance.jsonFileLanguage;
//   }

//   showModal() {
//     document.querySelector<HTMLAnchorElement>('.footer-cookie-link')?.click();
//   }

//   enableSetting(type: 'comfort' | 'analysis' | 'marketing') {
//     if (typeof window !== 'undefined' && window['selectiveConsent']) {
//       window['selectiveConsent'](type === 'marketing' ? 'personalised' : type);
//     }
//   }
// }
